// FileUpload.tsx
import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Paper,
  Button,
  Link,
} from "@mui/material";
import Box from "@mui/material/Box";
import { keyframes } from "@mui/system";
import { styled as muiStyled } from "@mui/system";

import Proviso from "../../components/Proviso/Proviso";
import { getExtension } from "../../components/Utils/utils";
import LoadingDialog from "../../components/LoadingDialog/LoadingDialog";

// redux
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  // estimate,
  estimationAmountAsync,
  selectEstimationAmount,
} from "../../features/estimationAmount/estimationAmountSlice";
import { addEstimationToList } from "../../features/estimationAmountList/estimationAmountListSlice";
import {
  isEstimationAmountDisplay,
  selectEstimationAmountDisplay,
} from "../../features/isEstimationAmountDisplay/isEstimationAmountDisplaySlice";
import {
  fileSelected,
  selectIsFileSelected,
} from "../../features/isFileSelected/isFileSelectedSlice";

import { S3 } from "aws-sdk";

// スタブ
// import { fetchEstimationAmount } from "../../features/estimationAmount/estimationAmountAPI";

// ボタンアニメーションの設定
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

// カスタムプロパティを持つ型を定義
interface AnimatedButtonProps {
  animate: boolean;
}

const AnimatedButton = muiStyled(Button, {
  shouldForwardProp: (prop) => prop !== "animate",
})<AnimatedButtonProps>(({ animate }) => ({
  animation: animate ? `${pulse} 1s infinite` : "none",
  transition: "all 0.3s ease",
}));

// S3の設定: 環境変数からリージョンも読み込む
const s3 = new S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION, // 環境変数からリージョンを読み込む
});

const FileUpload: React.FC = () => {
  // redux
  const estimation = useAppSelector(selectEstimationAmount);
  const estimationAmountDisplay = useAppSelector(selectEstimationAmountDisplay);
  const isFileSelected = useAppSelector(selectIsFileSelected);
  const dispatch = useAppDispatch();
  // fileUpload
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // DialogState
  const [isDialogOpen, setDialogOpen] = useState(false);
  // estimationState
  const [isEstimating, setIsEstimating] = useState(false); // 状態を追加
  // ボタンアニメーションの有無
  const [animate, setAnimate] = useState(false);
  // 見積開始ボタン押下時
  const handleEstimationClick = useCallback(() => {
    // ファイルの状態を確認しファイルが設定されていない場合はアラートを出して終了
    // console.log(isFileSelected.selectedFileName);
    if (isFileSelected.selectedFileName === null) {
      alert("ファイルをアップロードしてください");
      return;
    }
    // 非同期処理を開始するためのフラグをセットします
    setIsEstimating(true);
    // 見積もり実行でアニメーションを停止
    setAnimate(false);
    // console.log("アニメーション停止");
  }, [isFileSelected.selectedFileName]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      if (isMounted) {
        // ローディング画面を表示
        setDialogOpen(true);
        // 表示フラグをfalseに変更
        dispatch(isEstimationAmountDisplay(false));
        let response: any = null;
        //アップロードファイルの読み込み
        if (selectedFile) {
          // 現在のタイムスタンプを取得
          const timestamp = new Date().getTime();
          // ファイル名にタイムスタンプを追加してユニークなキーを作成
          const uniqueFileName = `${timestamp}-${selectedFile.name}`;
          const params = {
            Bucket: "raku-cad-trace-bucket",
            Key: uniqueFileName,
            Body: selectedFile,
          };

          try {
            // S3アップロードを実行
            await s3.upload(params).promise();
            // console.log("Success", data);
            // alert("ファイルのアップロードに成功しました！");
            // console.log("ファイルアップロードに成功しました。");
            // ファイルアップロード後にアップロード先の画像URLを取得
            const extention = getExtension(selectedFile.name);
            // ここでLambdaに問い合わせる処理
            // これが本番コード
            response = await dispatch(
              estimationAmountAsync({
                fileName: uniqueFileName,
                fileNameOrigin: selectedFile.name,
                extension: extention,
              })
            );
          } catch (err) {
            console.error("Error", err);
            setDialogOpen(false);
            alert("ファイルのアップロードに失敗しました。");
          }
        }

        // すぐにレスポンスが来るので来た後に待ち時間をいれる。
        // 3秒程度待機
        setTimeout(() => {
          if (estimationAmountAsync.fulfilled.match(response)) {
            // ダイアログを非表示にして
            setDialogOpen(false);
            // 見積金額を表示
            dispatch(isEstimationAmountDisplay(true));
            if (response.payload.estimationAmount === -1) {
              //何もしない
            } else {
              // リストを更新
              dispatch(addEstimationToList(response.payload));
            }
          }
          // 非同期処理が完了したら、フラグをリセットします
          setIsEstimating(false);
        }, Math.floor(Math.random() * 3000) + 2000); //3秒程度を計算
      }
    };

    if (isEstimating) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [isEstimating, selectedFile, dispatch]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const extension = getExtension(file.name).toLowerCase();
      // ファイルがPNGまたはJPGの場合のみ、次のバリデーションを行う
      handleFileValidation(
        file,
        (validatedFile) => {
          setSelectedFile(validatedFile);
          setAnimate(true);
          dispatch(fileSelected({ selectedFileName: validatedFile.name }));
        },
        extension
      );
    } else {
      alert("ファイルをアップロードしてください");
      setSelectedFile(null);
      setAnimate(false);
    }
  };

  // ファイルサイズと拡張子をチェックし、条件に応じた処理を行う共通関数
  const handleFileValidation = (
    file: File | null,
    onValidFile: (file: File) => void,
    extension: string
  ) => {
    if (file) {
      // PNGまたはJPGファイルであることを確認
      if (extension === "png" || extension === "jpg") {
        // ファイルサイズをMB単位で計算
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB >= 10) {
          // ファイルサイズが10MB以上の場合の処理
          alert("ファイルサイズが規定の10MBを超えています。");
        } else {
          // ファイルサイズが10MB未満の場合の処理
          onValidFile(file);
        }
      } else {
        alert(
          "サポートされていないファイル形式です。PNGまたはJPGファイルを選択してください。"
        );
      }
    }
  };

  // ドラッグ＆ドロップ時にブラウザが別のタブでファイルを開く挙動を防止するための関数
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  // ファイルがドラッグ＆ドロップされた時の動作
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file) {
      const extension = getExtension(file.name).toLowerCase();
      // ファイルがPNGまたはJPGの場合のみ、次のバリデーションを行う
      handleFileValidation(
        file,
        (validatedFile) => {
          setSelectedFile(validatedFile);
          setAnimate(true);
          dispatch(fileSelected({ selectedFileName: validatedFile.name }));
        },
        extension
      );
    } else {
      alert("ファイルをアップロードしてください");
      setSelectedFile(null);
      setAnimate(false);
    }
  };

  const content = `
    アップロードできるデータ容量は1ファイル10MBまで<br/>
    複数ファイルをお見積りする場合は1ファイルずつ見積を実行してください。<br/>
    多量の場合は、お問い合わせフォームからお見積もり依頼いただくことも可能です。<br/>
    その場合は、担当スタッフが対応いたしますので、申し訳ございませんがお時間いただいております。<br/>
    アップロードできるファイルはjpgまたはpngの画像ファイルのみです。<br/>
    圧縮ファイルをアップロードしても見積もりはできません。<br/>
    パスワード保護されているファイルは受付できません。<br/>
    jpgまたはpngファイル以外のファイルの場合お問い合わせ窓口へご連絡ください。<br/>
    特殊記号などファイル名に使用の場合、正しいお見積りができない場合があります。ファイル名を変更しお試しください。<br/>
    ブラウザやページを閉じるまたは更新動作を行うとアップロードデータ、見積データは削除されます。<br/>
  `;
  return (
    <div>
      <LoadingDialog isOpen={isDialogOpen} message={"見積中"} />
      <Container>
        <Paper
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          sx={{
            padding: 2,
            textAlign: "center",
            backgroundColor: "#EDEDED",
            border: "2px dashed #aaa",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <label htmlFor="fileInput">
            <Typography variant="h6" component="div">
              ここに
              <Typography
                variant="h5"
                component="span"
                sx={{ fontWeight: "bold" }}
              >
                1ファイルずつ
              </Typography>
              <br />
              画像ファイルをドラッグ＆ドロップ
            </Typography>
            <Typography variant="h6" component="div">
              または　ここをクリックして画像ファイルを選択してください
              <br />
              jpgまたはpngファイルのみサポートしています
            </Typography>
          </label>
          {selectedFile && (
            <Typography
              variant="h5"
              component="div"
              sx={{ marginTop: 2, fontWeight: "bold" }}
            >
              選択中のファイル: {selectedFile.name}
            </Typography>
          )}
        </Paper>
        <Proviso content={content} />
        <Box
          sx={{
            display: "flex", // フレックスボックスを有効にする
            flexDirection: "column", // 縦方向にフレックスアイテムを配置
            alignItems: "center", // 水平方向にセンタリング
            textAlign: "center", // テキストをセンタリング
          }}
        >
          <Box component="div">
            {selectedFile && (
              <Typography variant="h5" component="div" sx={{ marginTop: 2 }}>
                選択中のファイル: {selectedFile.name}
              </Typography>
            )}
          </Box>
          <Box marginTop={2}>
            <Box
              component="div"
              sx={{
                backgroundColor: "#9e9e9e",
                width: "100px",
                height: "43.3px",
                clipPath: "polygon(50% 100%, 0 0, 100% 0)",
                margin: "0 auto", // 自動的に左右のマージンを設定してセンタリング
              }}
            ></Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <AnimatedButton
            animate={animate}
            variant="contained"
            color="primary"
            // onClick={handleUpload}
            onClick={handleEstimationClick}
            disabled={!selectedFile}
            sx={{ fontSize: "18px", marginTop: 2 }}
          >
            見積開始
          </AnimatedButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%" // 要素の高さを調整する必要がある場合に追加
          marginTop="20px" // 上側のマージンを設定（適宜調整）
        >
          {estimationAmountDisplay && (
            <>
              {estimation.estimationAmount === -1 ? (
                <Typography variant="h6" color="error">
                  単価設定をAIが判断できませんでした。別途フォームよりお見積りをご依頼ください。
                  <br />
                  担当者よりご連絡いたします。
                  <Link
                    href="https://cadtrace.jp/form/"
                    color="primary"
                    underline="always"
                  >
                    →問い合わせフォームへ
                  </Link>
                </Typography>
              ) : (
                <>
                  <Typography variant="h5">見積価格：</Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{ textDecoration: "underline" }}
                  >
                    {estimation.estimationAmount}
                  </Typography>
                  <Typography variant="h5">円</Typography>
                </>
              )}
            </>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default FileUpload;
