import { v4 as uuidv4 } from "uuid";

export function getExtension(filename: string): string {
  // 最後のピリオドの位置を見つける
  const index = filename.lastIndexOf(".");

  // ピリオドが見つからないか、ファイル名の最後にピリオドがある場合は空の文字列を返す
  if (index === -1 || index === filename.length - 1) {
    return "";
  }

  // ピリオドの次の文字から文字列の終わりまでを返す
  return filename.substring(index + 1);
}

export function generateUUID(): string {
  return uuidv4(); // UUID v4を生成して返す
}

/**
 * 数値をカンマ区切りの文字列に変換する関数
 * @param amount - 金額（数値）
 * @returns カンマ区切りの金額（文字列）
 */
export const formatCurrency = (amount: number): string => {
  return amount
    .toLocaleString("ja-JP", { style: "currency", currency: "JPY" })
    .replace("￥", "");
};

/**
 * 現在の日付をYYYY年MM月DD日の形式で取得する関数
 * @returns 現在の日付（文字列）
 */
export const getFormattedToday = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // 月は0から始まるため+1
  const day = String(now.getDate()).padStart(2, "0");

  return `${year}年${month}月${day}日`;
};

/**
 * ISO 8601形式の日時をYYYY年MM月DD日の形式で取得する関数
 * 時間部分はトリミングされます。
 * @returns YYYY年MM月DD日
 */
export const getformatDateFromISOFormat = (
  dateString: string | null
): string | null => {
  if (dateString === null) {
    return null;
  }
  const datePart = dateString.split("T")[0]; // '2024-05-28'
  const [year, month, day] = datePart.split("-");
  return `${year}年${month}月${day}日`;
};

/**
 * JSTのISO 8601形式の文字列をDateから生成します。DateはUTCで入力されます。
 * @param date
 * @returns JSTのISO 8601形式の文字列
 */
export const toJSTISOString = (date: Date): string => {
  const jstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000); // JSTに変換

  const year = jstDate.getUTCFullYear();
  const month = String(jstDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(jstDate.getUTCDate()).padStart(2, "0");
  const hours = String(jstDate.getUTCHours()).padStart(2, "0");
  const minutes = String(jstDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(jstDate.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(jstDate.getUTCMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+09:00`;
};


/**
 * 現在の時刻からJSTの年月日を生成します。
 * @returns JSTのyyyyMMdd
 */
export const getCurrentDateInJST = (): string => {
  // 現在の日時を取得
  const now = new Date();

  // 日本標準時に変換するため、UTC時間に日本の時差（9時間）を足す
  const jstOffset = 9 * 60 * 60 * 1000;
  const jstNow = new Date(now.getTime() + jstOffset);

  // 年、月、日を取得し、「yyyyMMdd」形式にフォーマット
  const year = jstNow.getUTCFullYear();
  const month = String(jstNow.getUTCMonth() + 1).padStart(2, "0"); // 月は0から始まるため+1
  const day = String(jstNow.getUTCDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};

/**
 * 現在時刻を日本標準時（JST）のタイムゾーンでISO 8601形式のタイムスタンプとして取得します。
 * 例: "2024-04-27T14:23:30+09:00"
 *
 * @returns JSTタイムゾーンの現在時刻をISO 8601形式の文字列で表したもの
 */
export const generateCurrentJSTIso8601Timestamp = (): string => {
  // 現在の日時を取得
  const now = new Date();

  // システムのタイムゾーンオフセット（分単位）を取得し、ミリ秒に変換
  const timezoneOffsetInMs = now.getTimezoneOffset() * 60 * 1000;

  // UTC時刻を基準にJST（UTC+9）に調整
  const jstTime = new Date(now.getTime() + timezoneOffsetInMs + (9 * 60 * 60 * 1000));

  // 各日時コンポーネントを2桁にフォーマットするヘルパー関数
  const pad = (n: number): string => n.toString().padStart(2, '0');

  // 年、月、日、時、分、秒を取得
  const year = jstTime.getFullYear();
  const month = pad(jstTime.getMonth() + 1); // 月は0から始まるため+1
  const day = pad(jstTime.getDate());
  const hours = pad(jstTime.getHours());
  const minutes = pad(jstTime.getMinutes());
  const seconds = pad(jstTime.getSeconds());

  // ISO 8601形式の文字列を構築
  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+09:00`;

  return isoString;
}

/**
 * ISO 8601形式の日時文字列を受け取り、yyyy-MM-dd HH:mm:ss形式の文字列としてJSTで返します。
 * 例: "2024-04-27T14:23:30+09:00" を "2024-04-27 14:23:30" に変換
 *
 * @param isoString ISO 8601形式の日時文字列
 * @returns yyyy-MM-dd HH:mm:ss形式の文字列（JST）
 * @throws 無効なISO 8601文字列が渡された場合にエラーをスロー
 */
export const formatIso8601ToJst = (isoString: string): string => {
  // 入力されたISO 8601文字列をDateオブジェクトに変換
  const date = new Date(isoString);
  
  // 有効な日付かどうかをチェック
  if (isNaN(date.getTime())) {
    throw new Error('無効なISO 8601形式の日時文字列が渡されました。');
  }

  // 日本標準時（JST）のタイムゾーンオフセット（分単位）
  const JST_OFFSET_MINUTES = 9 * 60;

  // UTC時刻を基準にJSTに調整
  // Dateオブジェクトは内部的にUTCを基準としているため、UTC時間にJSTオフセットを加える
  const jstTime = new Date(date.getTime() + JST_OFFSET_MINUTES * 60 * 1000);

  // 各日時コンポーネントを2桁にフォーマットするヘルパー関数
  const pad = (n: number): string => n.toString().padStart(2, '0');

  // UTCを基準にJSTに調整した日時の各コンポーネントを取得
  const year = jstTime.getUTCFullYear();
  const month = pad(jstTime.getUTCMonth() + 1); // 月は0から始まるため+1
  const day = pad(jstTime.getUTCDate());
  const hours = pad(jstTime.getUTCHours());
  const minutes = pad(jstTime.getUTCMinutes());
  const seconds = pad(jstTime.getUTCSeconds());

  // yyyy-MM-dd HH:mm:ss形式の文字列を構築
  const formattedString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedString;
};
